// Gatsby supports TypeScript natively!
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Terms = () => (
  <Layout>
    <SEO title="Terms of Use - Lawn Chair Sauvignon Blanc" />    
    <div className="relative z-30 -mt-1">
      <div className="gPadding w-full relative max-w-1440 mx-auto text-black">
        <div className="privacy-copy relative my-32">
          
          <h2 className="text-center mb-8">Terms of Use</h2>

          <p>Welcome to our website. The following Terms of Use (&ldquo;Terms of Use&rdquo;) govern your use of this website. These Terms of Use also apply to your use of our mobile apps and our official social media channels, including on Facebook, Twitter, Instagram, and LinkedIn (&ldquo;Social Media Sites&rdquo;), to the extent permitted by the social media platform&rsquo;s terms of use. References to &ldquo;we,&rdquo; &ldquo;our,&rdquo; &ldquo;us&rdquo; or &ldquo;Our Brand&rdquo; herein refer to Anecwine Corporation,and its brands, affiliates and service providers, and references to our &ldquo;Site&rdquo; refer to this website, our mobile apps, and our Social Media Sites.</p>
          <p>THESE TERMS OF USE INCLUDE A CLASS ACTION WAIVER AND REQUIRE BINDING ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES. DETAILS ARE BELOW.</p>
          <ol>
          <li><strong>By Using Our Site, You Agree to These Terms of Use</strong></li>
          </ol>
          <p>We may modify these Terms of Use at any time. Each time you access our Site, it is your responsibility to review these Terms of Use for updates. Your continued use of the Site after we have posted any modifications means you accept such modified Terms of Use. If you disagree with these Terms of Use (as may be modified) or are dissatisfied with our Site, your sole remedy is to discontinue using our Site. We may offer promotional activities or benefits, such as wine club membership benefits or events, wine education services, sweepstakes or contests, and sometimes additional terms may apply to these activities or benefits (&ldquo;Promotional Terms&rdquo;). If these Terms of Use are inconsistent with those Promotional Terms, those Promotional Terms will control.</p>
          <ol>
          <li><strong>You Must be 21 Years or Older to Use Our Site</strong></li>
          </ol>
          <p>If you are younger than 21, please exit our Site immediately. We do not sell alcohol to persons under 21. By continuing, you confirm that you are 21 or older. You are responsible for complying with applicable laws and ensuring that your access to our Site is legal in your country of residence.</p>
          <ol>
          <li><strong>Privacy Practices</strong></li>
          </ol>
          <p>Any personal information submitted by you via our Site is governed by our&nbsp;<u>Privacy Policy.</u>&nbsp;Please review this policy to understand how we may use your personal information and our other privacy practices. California residents, please also see our Supplemental California Consumer Privacy Act Disclosures, contained in our&nbsp;<u>California Privacy Notice</u></p>
          <ol>
          <li><strong>Other Applicable Terms</strong></li>
          </ol>
          <p>Where available, the purchase of alcohol or other products from our Site is also governed by the other terms disclosed on this Site such as: Wine Club Terms, Shipping Terms and any other applicable terms posted to this Site.</p>
          <ol>
          <li><strong>Membership Account Information</strong></li>
          </ol>
          <p>Where applicable, you may need to register as a wine club member to access certain portions of our Site, and you may be required to be logged in to your account and have a valid payment method associated with it on file. To register, you must provide us with current, complete, and accurate information as prompted by the registration form and you will need to choose a user name and password. You are solely responsible for maintaining the confidentiality of your username and password and for all activity under your username and password. You may not use anyone else&rsquo;s username or password or permit others to use yours. You must notify us immediately of any suspected unauthorized use of your account or any inaccuracy in your registration information. We reserve the right to terminate membership or accounts, refuse service, or cancel orders in our sole discretion and without liability.</p>
          <ol>
          <li><strong>Site Content is Protected by U.S. and International Laws</strong></li>
          </ol>
          <p>All content included in or made available through our Site, such as text, images, logos, graphics, and functionality, is the property of Anecwines Corporation or its content suppliers and is protected by United States and international copyright, patent, trademark, trade secret, and other intellectual property laws. You may not use that content in any way whatsoever, except as expressly permitted by these Terms of Use (or in the case of copyright material, as permitted by United States copyright laws). Our trademarks and trade dress may not be used in connection with any other party&rsquo;s product or service in any manner that is likely to cause confusion among customers or in any manner that disparages us. Subject to your compliance with these Terms of Use, Anecwines Corporation grants you a limited, non-exclusive, non-transferable, non-sublicensable, revocable license to access and make personal, non-commercial use of our Site. In no event may you use our Site, or any of its content, in any commercial manner. We may revise this consent to use, or withdraw access to, our Site at any time without notice or liability to you.</p>
          <ol>
          <li><strong>User Comments and Other Submissions</strong></li>
          </ol>
          <p>We welcome your comments and feedback regarding our Site and our products. We do not, however, accept confidential or proprietary information. By posting or submitting any comments, ratings, reviews, suggestions, ideas, photos, images, content, and any other submissions (&ldquo;User Submissions&rdquo;) in connection with your use of our Site, you grant Anecwines Corporation an irrevocable, perpetual, worldwide, royalty-free, fully-paid, non-exclusive, transferable license to use, make derivative works of and exploit the User Submissions in any manner without limitation (including for any commercial purpose) in any form of medium, whether now existing or developed in the future. You also grant Anecwines Corporation the right to use (if it chooses) the name that you submit in connection with the User Submission. You may not use a false e-mail address or otherwise mislead as to the origin of any User Submission. If you post or submit any User Submissions, you represent that those submissions are not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property or other rights, or otherwise injurious to any person or entity and that none of those submissions contain any viruses, trojan horses or any computer programming routines or engines that are intended to damage or gain unauthorized access to any computer system or network. You acknowledge that you may be personally liable for any User Submissions that you post or submit in violation of any laws, any third party's rights, or these Terms of Use.</p>
          <ol>
          <li><strong>Intellectual Property Complaints</strong></li>
          </ol>
          <p>If you believe that your intellectual property has been copied and/or provided to us in a way that constitutes copyright infringement, please provide us with the information specified below, in writing. Please do not use this procedure for any other purpose. Include the following information:</p>
          <ul>
          <li>Signature of person authorized to act on behalf of copyright owner;</li>
          <li>Description of copyrighted work;</li>
          <li>Description of where the infringing material is located on our Site;</li>
          <li>Your address, telephone number, and e-mail address;</li>
          <li>Statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent or law; and</li>
          <li>Statement by you, made under penalty of perjury, that the information in your notice is accurate and that you are the copyright owner or authorized to act on behalf of the copyright owner.</li>
          </ul>
          <p>If the content was removed under the take-down procedures of the United States Digital Millennium Copyright Act (DMCA), you may be able to file a DMCA counter-notification. In these cases, you'll receive further instructions about this process in the notification you receive from Anecwines Corporation.</p>
          <p>Our designated agent for notice of claims of copyright infringement is:</p>
          <p><strong>Legal Department<br />Anecwines Corporation<br />845 Third Ave, Ste 607<br />New York, NY 10022<br /><br />e-mail: <a href="mailto:legal@anecwines.com?subject=Copyright%20Notice">legal@anecwines.com</a></strong></p><br/>
          <ol>
          <li><strong>Electronic Communications</strong></li>
          </ol>
          <p>When you visit our Site or send e-mails or other communications from your desktop or mobile device, you are communicating with us electronically. You consent to receive communications from us electronically. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that those communications be in writing.</p>
          <ol>
          <li><strong>Social Media Rules</strong></li>
          </ol>
          <p>We enjoy engaging with our customers via our Social Media Sites, but you must adhere to the below rules whenever you post on our Social Media Sites. You must be 21 or over to visit or post on our Social Media Sites and any communications regarding our products must not encourage or depict excessive consumption, underage drinking or misuse of our alcohol products. We reserve the right (but not the obligation) to remove any posts or content for any reason, including those that violate the below rules or that we deem offensive, inappropriate or unacceptable in our sole discretion, but we do not regularly review posted content. We take no responsibility for any content posted by you or any third party.</p>
          <ul>
          <li><strong>Respect Others</strong>. Do not post any content that is derogatory, disparaging, promotes bigotry, is abusive or threatening, vulgar or obscene. Follow the golden rule &ndash; treat members of our community fairly and with respect. Nudity and sexually explicit content is not allowed.</li>
          <li><strong>Follow the Law.</strong>&nbsp;Illegal activity or discussions encouraging illegal activity will not be tolerated.</li>
          <li><strong>Don&rsquo;t Infringe</strong>. Do not use it unless you have the right to &ndash; this means, don&rsquo;t post photos, images, logos, songs, text or other content that you don&rsquo;t own or have the right to use.</li>
          <li><strong>Respect Privacy</strong>. Do not post or solicit anyone&rsquo;s name, phone number, address, email address or other personal information. Don&rsquo;t spam or transmit junk mail.</li>
          <li><strong>21+ Only</strong>. Do not post photos of, or otherwise feature or reference, any individual under the age of 21.</li>
          </ul>
          <p>Of course, you must also abide by the terms of use of the social media platform.</p>
          <ol>
          <li><strong>Disclaimers, Limitation of Liability, and Indemnification</strong></li>
          </ol>
          <p>Our Site may contain links to other websites. We are not responsible for those websites, their content or any goods or services available on those websites. Inclusion of any linked website on our site does not imply approval or endorsement by us. When you access these third-party sites, you do so at your own risk.</p>
          <p>While we are committed to safeguarding your privacy online, we cannot guarantee and make no representations that communications will be absolutely secure. By submitting your personal information to us, you acknowledge that there is no guarantee of security and that we have no liability for any interception or unauthorized access.</p>
          <p>OUR SITE AND ITS CONTENTS ARE PROVIDED ON AN "AS IS" AND &ldquo;AS AVAILABLE&rdquo; BASIS. YOUR USE OF OUR SITE IS AT YOUR OWN RISK.</p>
          <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, ANECWINES CORPORATION DISCLAIMS ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. ANECWINES CORPORATION DOES NOT WARRANT THAT YOUR USE OF OUR SITE WILL BE UNINTERRUPTED OR ERROR FREE, OR THAT OUR SITE OR ITS SERVER ARE FREE OF VIRUSES OR OTHER HARMFUL ELEMENTS. ALTHOUGH ANECWINES CORPORATION ENDEAVORS TO PROVIDE ACCURATE PRODUCT INFORMATION, IT DOES NOT MAKE ANY REPRESENTATIONS REGARDING THE ACCURACY OR RELIABILITY OF INFORMATION ON OUR SITE. NEITHER ANECWINES CORPORATION NOR ITS VENDORS OR CONTENT PROVIDERS SHALL BE LIABLE TO ANY PERSON OR ENTITY FOR ANY DIRECT OR INDIRECT LOSS, DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL, PUNITIVE, SPECIAL OR OTHERWISE), INJURY, DEATH, OR LIABILITY OF ANY KIND OR CHARACTER WHATSOEVER, WHETHER OR NOT IT KNEW OR SHOULD HAVE KNOWN THE LIKELIDHOOD OF SUCH DAMAGES, ARISING OUT OF OUR SITE OR YOUR PARTICIPATION IN ANY OF OUR PROMOTIONAL ACTIVITIES. IF THE ABOVE WARRANTY EXCLUSIONS OR LIMITATIONS OF LIABILITY ARE FOR ANY REASON HELD UNENFORCEABLE OR INAPPLICABLE, YOU AGREE THAT OUR AGGREGATE LIABILITY SHALL NOT EXCEED $100.</p>
          <p>YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS ANECWINES CORPORATION AND ITS SHAREHOLDERS, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, DISTRIBUTORS, AND VENDORS FROM AND AGAINST ANY THIRD-PARTY CLAIMS, DEMANDS, LIABILITIES, COSTS OR EXPENSES, INCLUDING REASONABLE ATTORNEYS' FEES, ARISING OUT OF YOUR BREACH OF THESE TERMS OF USE OR ARISING OUT OF ANY CONTENT THAT YOU POST.</p>
          <p>Some states do not allow the exclusion or limitation of certain warranties or liabilities, in which case the above limitations or exclusions may not apply to you.</p>
          <ol>
          <li><strong>Disputes</strong></li>
          </ol>
          <p>Please contact our Consumer Engagement team with any concerns by utilizing the&nbsp;<a href="https://www.barefootwine.com/contact-us">Contact</a>&nbsp;page on this Site. Any disputes and claims between us that cannot be resolved by our Consumer Engagement team will be resolved in accordance with this dispute resolution provision.</p>
          <p><strong>Any dispute or claim related in any way to your use of or otherwise arising out of our Site, including any products purchased through our Site or your participation in any promotional activities, will be resolved by binding arbitration rather than in court</strong>, except as specified below. Claims for public injunctive relief (as defined by law) are not subject to this mandatory binding arbitration provision. Similarly, in lieu of arbitration, you may assert claims in small claims court having jurisdiction if your claims qualify.</p>
          <p><strong>There is no judge or jury in arbitration and court review of an arbitration award is limited. However, an arbitrator can award on an individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages).</strong></p>
          <p><strong>You may opt out of these dispute resolution procedures </strong><strong>by </strong><strong>emailing&nbsp;<a href="mailto:legal@anecwines.com?subject=Dispute%20Resolution">legal@anecwines.com</a></strong><strong>to request the opt out form. Your completed opt out form must be received by </strong><strong>Anecwines Corporation</strong><strong> within 30 days of the date when you first accept these Terms of Use, or it will not be valid.</strong></p>
          <p>The arbitration will be conducted by the American Arbitration Association (AAA) under the AAA&rsquo;s Commercial Arbitration Rules and Supplementary Procedures for Consumer Related Disputes (collectively, the &ldquo;AAA Rules&rdquo;). The AAA Rules are available at&nbsp;<a href="http://www.adr.org/">www.adr.org</a>&nbsp;or by calling 1-800-778-7879. Payment of all filing, administration and arbitrator fees will be governed by the AAA Rules. Anecwines Corporation will reimburse those fees for claims demanding less than $10,000 unless the arbitrator determines the claims are frivolous. Likewise, Anecwines Corporation will not seek attorneys' fees and costs in arbitration unless the arbitrator determines the claims are frivolous. Unless we agree otherwise, the arbitration hearing will take place in the county of your billing address.</p>
          <p><strong>We each agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action.</strong>&nbsp;If for any reason a claim proceeds in court rather than in arbitration,&nbsp;<strong>we each waive any right to a jury trial and agree that such claim may be brought only in the </strong><strong>state and federal courts located in Nassau County, New York, USA</strong>.This provision will survive any termination of these Terms of Use.</p>
          <ol>
          <li><strong>Governing Law and General</strong></li>
          </ol>
          <p>The Federal Arbitration Act, applicable federal arbitration laws and the laws of the State of <strong>Nassau County, New York, USA</strong>, without regard to principles of conflict of laws, will govern these Terms of Use and any dispute of any sort that might arise between you and Anecwines Corporation.</p>
          <p>These Terms of Use, and the other policies posted on our Site, constitute the entire agreement between us regarding our Site. Anecwines Corporation&rsquo;s failure to exercise any right under these Terms of Use does not operate as a waiver of such right. All section titles are for convenience and do not affect the meaning of any provision. All uses of &ldquo;including&rdquo; or &ldquo;includes&rdquo; may not be interpreted as limiting. If any provision is unlawful, void or unenforceable, that provision is deemed severable and will not affect any remaining provision.</p>

        </div>
      </div>
    </div>

   
  </Layout>
)

export default Terms
